import { hookstate } from '@hookstate/core';



export default hookstate({
    user: {
        forvards: []
    },
    ovner: {
        
    }
});